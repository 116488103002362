export default {
  colors: {
    primary: "#EC4D7B",
    darkPrimary: "#e82a61",
    secondary: "#163A74",
    lightGray: "#555",
    gray: "#333",
    darkGray: "#111",
    black: "#000",
    white: "#fff",
    heading: "#163A74",
    blue: "#1e52bd",
    darkBlue: "#173A74",
    grayBase: "#EDF2F6",
  },
}
