const AWS_REGION = process.env.GATSBY_AWS_REGION
export const AWS_CONFIG = {
  Auth: {
    region: AWS_REGION,
    userPoolId: process.env.GATSBY_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.GATSBY_AWS_USER_POOL_WEB_CLIENT_ID,
    identityPoolId: process.env.GATSBY_AWS_IDENTITY_POOL_ID,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.GATSBY_AWS_BUCKET, //REQUIRED -  Amazon S3 bucket name
      region: AWS_REGION, //OPTIONAL -  Amazon service region
    },
  },
  aws_appsync_graphqlEndpoint: process.env.GATSBY_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: AWS_REGION,
  aws_appsync_authenticationType:
    process.env.GATSBY_AWS_APPSYNC_AUTHENTICATION_TYPE,
  aws_appsync_apiKey: process.env.GATSBY_AWS_APPSYNC_API_KEY,
  aws_bucket: process.env.GATSBY_AWS_BUCKET,
}
