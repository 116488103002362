/*
 *
 * This file contains all the urls used in the code.
 *
 * */

export const MCHRI_ExternalLink = "https://www.monash.edu/medicine/sphpm/mchri"
export const MonashUniversity_ExternalLink = "https://www.monash.edu/"
export const MonashHealth_ExternalLink = "https://monashhealth.org"
export const MonashFacebook_ExternalLink =
  "https://www.facebook.com/monashmchri/"

export const MonashPrivacyPolicy_ExternalLink =
  "https://askempdf.s3.us-east-2.amazonaws.com/termsandconditions_20220412.pdf"

export const MonashDataProcedure_ExternalLink =
  "https://www.monash.edu/__data/assets/pdf_file/0003/790086/Privacy.pdf"
export const DiscussionForumStartTopic_InternalLink = "/thread/start-topic/"
export const DiscussionForumEditTopic_InternalLink = "/thread/edit-topic"
export const DiscussionForumThread_InternalLink = "/thread/"
export const Dashboard_InternalLink = "/dashboard/"
export const SignUp_InternalLink = "/signup/"
export const Login_InternalLink = "/login/"
export const Logout_InternalLink = "/log-out/"
export const Profile_InternalLink = "/profile/"
export const QuestionPromptList_InternalLink = "/qpl/"
export const SelfAssessment_InternalLink = "/self-assessment/"
export const Lifestyle_InternalLink = "/lifestyle/"
export const DiscoveryPage_InternalLink = "/discover/"
export const ManageLifeStylePage_InternalLink = "/manage/"
export const Discussion_InternalLink = "/discuss/"

// <a href="https://pngtree.com/free-backgrounds">free background photos from pngtree.com</a>
