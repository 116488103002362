import React from "react"
import styled from "styled-components"
import {
  appButtonTWS,
  darkAppButtonTWS,
  makeNewClassName,
  roundedAppButtonTWS,
} from "../../styles/tailwindClassNames"
import { iAppButton } from "../types"
import { Button as RBButton } from "react-bootstrap"

const TitleComponent: React.FC<{ title: string | undefined }> = ({ title }) => {
  if (title) return <span className="leading-none">{title}</span>
  return null
}

export interface ButtonProps {
  readonly children: React.ReactNode
  readonly onClick?: Function
  readonly outline?: boolean
  readonly className?: string
  readonly style?: any
  readonly size?: string
  readonly light?: boolean
  readonly id?: string
}

const StyledButton = styled(RBButton)`
  border-radius: 20px;
  font-size: 14px;
  padding: 6px 16px 7px;
  font-family: "Open Sans";
  font-weight: 600;
  white-space: nowrap;
`

export const Button: React.FC<ButtonProps> = ({
  children,
  onClick,
  outline,
  className,
  style,
  size,
  light,
  id,
}) => {
  let variant
  if (outline) {
    variant = "outline-primary"
  }
  let btnStyle: any = {}
  if (size === "lg") {
    btnStyle.padding = "12px 28px"
    btnStyle.borderRadius = "24px"
    btnStyle.fontSize = "16px"
  }
  if (light) {
    btnStyle.color = "#EC4D7B"
    btnStyle.background = "rgba(0, 0, 0, 0.05)"
    btnStyle.border = "none"
  }
  return (
    <StyledButton
      style={Object.assign({}, btnStyle, style)}
      className={className}
      onClick={onClick}
      id={id}
      variant={variant}
    >
      {children}
    </StyledButton>
  )
}

export const AppButton: React.FC<iAppButton> = ({
  title,
  Icon,
  className,
  onClick,
  iconOnRight,
  ...rest
}) => {
  const newClassName = makeNewClassName([appButtonTWS, className || ""])
  const SeperatorDivComponent =
    Icon && title ? <div className="mx-1" /> : <React.Fragment />
  return (
    <button
      title={title || ""}
      className={newClassName}
      {...rest}
      onClick={onClick}
    >
      {!iconOnRight && Icon}
      {SeperatorDivComponent}
      <TitleComponent title={title} />
      {iconOnRight && SeperatorDivComponent}
      {iconOnRight && Icon}
    </button>
  )
}

export const applyClassName = (
  Button: React.FC<iAppButton>,
  classNameToApply: string
) => {
  return ({ className, ...rest }: iAppButton) => {
    const newClassName = makeNewClassName([className || "", classNameToApply])
    return <Button className={newClassName} {...rest} />
  }
}

export const RoundedAppButton = applyClassName(AppButton, roundedAppButtonTWS)
export const DarkAppButton = applyClassName(RoundedAppButton, darkAppButtonTWS)
export const WhiteAppButton = applyClassName(
  RoundedAppButton,
  "text-site-blue-dark transition duration-100 hover:bg-gray-200"
)

export const LightBlueAppButton = applyClassName(
  RoundedAppButton,
  "bg-site-blue-normal button-bg-site-blue-normal text-white no-border"
)

export const GreenAppButton = applyClassName(
  RoundedAppButton,
  "bg-site-green button-bg-site-green text-white no-border"
)
export const BlueAppButton = applyClassName(
  RoundedAppButton,
  "bg-site-blue button-bg-site-blue text-white no-border"
)
export const PinkAppButton = applyClassName(
  RoundedAppButton,
  "button-bg-site-pink text-white no-border w-24"
)

export const RedAppButton = applyClassName(
  RoundedAppButton,
  "bg-red-700 hover:bg-red-600 text-white"
)

export const GrayAppButton = applyClassName(
  RoundedAppButton,
  "bg-gray-200 hover:bg-gray-300"
)

export const SecondaryAppButton = applyClassName(
  RoundedAppButton,
  "bg-gray-200 hover:bg-gray-300 text-site-blue-dark"
)

export const GrayAppButtonNoHover = applyClassName(
  RoundedAppButton,
  "bg-gray-200 text-site-blue-dark"
)
